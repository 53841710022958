import styled from 'styled-components'

import { TypeStyle, fromMd, untilMd } from '@syconium/little-miss-figgy'

export const Price = styled.span`
  ${TypeStyle.css.headlineExtraExtraSmall};
  color: ${o => o.theme.color.text.primary.on.background};
  display: inline-block;
`
export const DiscountPriceWrapper = styled.div<{
  displayDiscountAsValue?: boolean | null
  price?: number | null
}>`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;

  ${fromMd} {
    display: ${o => (!!o.price && o.displayDiscountAsValue ? 'inline-flex' : 'flex')};
    justify-content: start;
  }
`

export const DiscountPrice = styled(Price)<{
  displayDiscountAsValue?: boolean
}>`
  ${untilMd} {
    margin: 0 0 0 0;
  }
  color: ${o =>
    o.displayDiscountAsValue
      ? o.theme.color.text.secondary.on.background
      : o.theme.color.text.critical.on.background};
`

export const CrossedOutPrice = styled.span`
  ${TypeStyle.css.headlineExtraExtraSmall};
  color: ${o => o.theme.color.text.tertiary.on.background};
  display: inline-block;
  text-decoration: line-through;
`

export const ValuePrice = styled(Price)`
  color: ${o => o.theme.color.text.tertiary.on.background};
`
