import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const FETCH_PAGE_VIEW = gql(`
  query FetchPageView($handle: String!, $preview: Boolean = false) {
    pageView(handle: $handle, preview: $preview) {
      handle
      hideEmailCaptureForm
      noindex
      socialDescription
      socialImage
      title
      themeOverride
      sections {
        __typename
        ... on AccordionView {
          maxWidth
          sections {
            id
            title
            content {
              __typename
              ... on RawHtmlSection {
                ...RawHtmlSectionFragment
              }
              ... on RichTextSection {
                ...RichTextSectionFragment
              }
            }
          }
        }
        ... on CollectionViewHeroSection {
          collectionViewHeroBackgroundColor: backgroundColor
          ctaText
          id
          imageDesktop
          imageMobile
          imagePlacement
          subtitle
          textColor
          title
          name
        }
        ...CollectionSectionFragment
        ...PageBannerSectionFragment
        ...PageHeroSectionFragment
        ...PageInteractiveSlideSectionFragment
        ...PageEditorialCarouselFragment
        ... on PageTabSection {
          id
          hideOnMobile
          hideOnDesktop
          name
          tabs {
            id
            tabSections {
              __typename
              ... on PageTabTileSection {
                id
                layoutVariantMobile
                layoutVariantDesktop
                tiles {
                  id
                  ctaText
                  href
                  image
                }
                tileVariantMobile
                tileVariantDesktop
              }
              ... on RawHtmlSection {
                ...RawHtmlSectionFragment
              }
            }
            text
            textColor
          }
          title
        }
        ... on PageTestimonialSection {
          id
          title
          subtitle
          testimonials {
            personName
            quote
            occupation
            instagram
            image
          }
        }
        ...PageTileSectionFragment
        ... on PageTitleSection {
          id
          title
          name
        }
        ... on ProductRecommendationsSection {
          hideColors
          hidePrice
          id
          name
          nostoPlacementId
          productCountLimit
          title
          titleAlignmentDesktop
          titleAlignmentMobile
          useNostoPlacementTitle
        }
        ... on RawHtmlSection {
          ...RawHtmlSectionFragment
        }
        ... on RichTextSection {
          ...RichTextSectionFragment
        }
        ... on VideoSection {
          ...VideoSectionFragment
        }
        ... on CustomComponentSection {
          id
          componentName
        }
      }
    }
  }
`)
