import styled from 'styled-components'

import { fromMd, untilMd } from '@syconium/little-miss-figgy'
import { SliderRow } from '@syconium/magnolia/src/brunswick/components/TileLayout/styles'

import { LayoutVariant } from '../../../../../../TileLayout'

export const Wrapper = styled.div`
  ${SliderRow}::before, ${SliderRow}::after {
    width: ${o => o.theme.dimensions.layout.sidePadding.md}px;
  }
  ${fromMd} {
    padding-top: ${o => o.theme.spacing(5)};
    padding-bottom: ${o => o.theme.spacing(5)};
  }
`
export const CoreStylesVariantWrapper = styled.div<{ layoutVariantDesktop: LayoutVariant }>`
  margin-right: ${o => o.theme.spacing(-6)};
  ${fromMd} {
    margin-left: ${o => (o.layoutVariantDesktop === 'grid' ? '-16px' : 0)};
    padding-top: ${o => o.theme.spacing(5)};
    padding-bottom: ${o => o.theme.spacing(5)};
  }
  ${untilMd} {
    margin-left: ${o => o.theme.spacing(4)};
    padding-bottom: ${o => o.theme.spacing(6)};
    margin-right: 0;
  }
`
export const EntryCard = styled.div`
  background-color: ${o => o.theme.color.deprecated.obsidian};
  height: 100%;
  border-radius: ${o => o.theme.spacing(2)};
`
export const EntryCardHeader = styled.div<{ oneWordPerLine: boolean; minWordLength: number }>`
  color: ${o => o.theme.color.deprecated.white.primary};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2.4px;
  font-weight: 700;
  padding: ${o => o.theme.spacing(4)} ${o => o.theme.spacing(4)} 0 ${o => o.theme.spacing(4)};
  overflow-x: hidden;
  hyphens: auto;
  width: ${o => o.minWordLength}em;
  ${fromMd} {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 2.7px;
    padding: ${o => o.theme.spacing(6)} ${o => o.theme.spacing(6)} 0 ${o => o.theme.spacing(6)};
  }
`

export const EntryCardDescription = styled.div`
  ${fromMd} {
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.65px;
    padding: ${o => o.theme.spacing(4)} ${o => o.theme.spacing(6)} 0 ${o => o.theme.spacing(6)};
  }
  color: ${o => o.theme.color.deprecated.white.primary};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  padding: ${o => o.theme.spacing(3)} ${o => o.theme.spacing(4)} 0 ${o => o.theme.spacing(4)};
`
