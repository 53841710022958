import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const CUSTOMER_FRAGMENT = gql(`
  fragment customerFragment on Customer {
    id
    email
    groups
    shopifyId
    addresses {
      nodes {
        address1
        address2
        city
        company
        country
        firstName
        lastName
        phone
        province
        shopifyId
        zip
      }
    }
    defaultAddress {
      address1
      address2
      city
      country
      firstName
      lastName
      phone
      province
      shopifyId
      zip
    }
    profile {
      avatarUri
      emailOptIn
      firstName
      id
      lastName
      occupation
      ordersCount
      phone
      stipend
      stylePreference
      birthday
    }
    portal {
      collectionHandle
      accountHeroSectionCG {
        backgroundColor
        ctaText
        ctaLink
        id
        imageDesktop
        imageMobile
        imagePlacement
        inlineVideoDesktop
        inlineVideoMobile
        inlineVimeoIdDesktop
        inlineVimeoIdMobile
        subtitle
        textColor
        title
        videoId
        videoPlatform
      }
    }
  }
`)

export const ORDER_FRAGMENT = gql(`
  fragment Order on Order {
    createdAt
    displayFinancialStatus
    displayFulfillmentStatus
    status
    id
    name
    total
    fulfillments {
      deliveredAt
      displayStatus
      trackingInfo {
        company
        number
        url
      }
    }
  } 
`)

export const MY = gql(`
  query my($first: Int, $reverse: Boolean = false, $after: String) {
    getUploadAvatarUrl
    my {
      ...customerFragment
      orders(first: $first, reverse: $reverse, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        nodes {
          createdAt
          displayFinancialStatus
          displayFulfillmentStatus
          id
          name
          status
          total
          fulfillments {
            deliveredAt
            displayStatus
            trackingInfo {
              company
              number
              url
            }
          }
          lineItems {
            nodes {
              id
              image {
                url
              }
              fulfillmentStatus
            }
          }
          fulfillments {
            displayStatus
          }
        }
      }
    }
  }
`)

export const MY_LIMITED_ORDER = gql(`
  query myLimitedOrder($first: Int, $reverse: Boolean = false, $after: String) {
    getUploadAvatarUrl
    my {
      ...customerFragment
      orders: ordersStorefront(first: $first, reverse: $reverse, after: $after) {
        nodes {
          name
          createdAt
          total
          fulfillments {
            trackingInfo {
              url
            }
          }
          lineItems {
            nodes {
              title
              image {
                altText
                url
              }
            }
          }
        }
      }
    }
  }
`)

export const MY_PROFILE = gql(`
  query myProfile {
    getUploadAvatarUrl
    my {
      id
      email
      groups
      shopifyId
      profile {
        avatarUri
        emailOptIn
        firstName
        id
        lastName
        occupation
        ordersCount
        phone
        stipend
        stylePreference
        birthday
      }
      portal {
        ...PortalProfile
      }
    }
  }
`)

export const MY_ADDRESSES = gql(`
  query myAddresses {
    getUploadAvatarUrl
    my {
      id
      email
      groups
      shopifyId
      addresses {
        nodes {
          address1
          address2
          city
          company
          country
          firstName
          lastName
          phone
          province
          shopifyId
          zip
        }
      }
      defaultAddress {
        address1
        address2
        city
        country
        firstName
        lastName
        phone
        province
        shopifyId
        zip
      }
    }
  }
`)

export const MY_ORDERS_DETAILS = gql(`
  query myOrderDetails($first: Int, $reverse: Boolean = false, $after: String) {
    getUploadAvatarUrl
    my {
      id
      profile {
        ordersCount
      }
      orders(first: $first, reverse: $reverse, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        nodes {
          ...Order
        }
      }
    }
  }
`)

export const MY_ORDERS_PAGE_INFO = gql(`
  query myOrdersPageInfo($first: Int, $reverse: Boolean = false, $after: String) {
    my {
      id
      orders(first: $first, reverse: $reverse, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`)

export const MY_EMBROIDERY_LOGOS = gql(`
  query myEmbroideryLogos {
    my {
      id
      embroideryLogos {
        id
        status
        name
        notes
        preview
        digitizationSetting
      }
    }
  }
`)
