import { useEffect, useState } from 'react'

import { useBrowserStoredState } from '@syconium/little-miss-figgy'
import { LocalizationContainer } from '@syconium/magnolia/src/brunswick/containers/localization/LocalizationContainer'
import { solidModalStyles } from '@syconium/magnolia/src/brunswick/styles/shared'
import { RegionAndLocalePickerContent } from '@syconium/magnolia/src/components/modals/RegionAndLocalePickerModal/RegionAndLocalePickerContent'
import { useModalState } from '@syconium/magnolia/src/components/modals/useModalState'
import * as RegionLocale from '@syconium/magnolia/src/components/region-locale'
import { SupportedRegionIdentifier } from '@syconium/weeping-figs'

import { NextModal } from '../NextModal'

import { IntlWelcomeModalContent } from './IntlWelcomeModalContent'

export const welcomeModalSessionKey = '@figs:seen-welcome-modal'
export function IntlWelcomeModal() {
  const { region } = LocalizationContainer.useContainer()
  const { state: alreadyWelcomedRegions, setState: setAlreadyWelcomedRegions } =
    useBrowserStoredState<SupportedRegionIdentifier[]>({
      initialState: [],
      storageKey: welcomeModalSessionKey,
    })
  const [showRegionPicker, setShowRegionPicker] = useState(false)

  const { isOpen, closeModal, openModal } = useModalState()

  const onRequestCloseCallback = () => {
    setShowRegionPicker(false)
    closeModal()
  }

  const onRequestRegionChangeCallback = () => {
    setShowRegionPicker(true)
  }

  // We use an effect so we don't open the modal server-side, it requires JavaScript to function well.
  // We also use an effect to open the modal because it is not triggered by user events or
  // state internal to React, but instead by the user's browser session data which is also not present
  // server-side.
  useEffect(() => {
    if (region.id !== 'US' && !alreadyWelcomedRegions.includes(region.id) && isOpen === false) {
      setAlreadyWelcomedRegions([...alreadyWelcomedRegions, region.id])
      openModal()
    }
  }, [openModal, region, alreadyWelcomedRegions, isOpen, setAlreadyWelcomedRegions])

  return (
    <NextModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onRequestCloseCallback}
      style={solidModalStyles}
    >
      {!showRegionPicker ? (
        <IntlWelcomeModalContent
          onRequestClose={onRequestCloseCallback}
          onRequestRegionChange={onRequestRegionChangeCallback}
        />
      ) : (
        <RegionAndLocalePickerContent onReqeustClose={onRequestCloseCallback} />
      )}
      <RegionLocale.IntlFooter onRequestClose={closeModal} />
    </NextModal>
  )
}
